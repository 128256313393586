import { RESET_STORE, SET_DEFAULT_COST_OPTIONS } from "../types";
import apiRequest from "../../@core/axios";

// Initial state
export const initialState = {
  lendersList: [],
  defaultOptions: {}
};

export function getGlobalRateTypes() {
  return apiRequest({
    url: "rate-types",
    method: "GET",
  });
}
export function getRateComparisonList(data: any) {
  return apiRequest({
    url: "rate-comparisons",
    method: "GET",
   ...data
  });
}

export function getAllRateComparisonList() {
  return apiRequest({
    url: "rate-comparisons?all=true",
    method: "GET"
  })
}

export function getARateComparison(id: any) {
  return apiRequest({
    url: `rate-comparisons/${id}`,
    method: "GET",
  });
}


export function createRateComparisonPlan(data: any, id: any = "") {
  return apiRequest({
    url: `rate-comparisons/${id}`,
    method: "POST",
    data,
    options: {
      headers: { "Content-Type": "multipart/form-data" },
    },
  });
}

export function duplicateRateComparisonPlan(id: any = "") {
  return apiRequest({
    url: `rate-comparisons/${id}/duplicate`,
    method: "POST",
    options: {
      headers: { "Content-Type": "multipart/form-data" },
    },
  });
}

export function deleteRateComparisonPlan(data: any = {}, id: any = "") {
  return apiRequest({
    url: `rate-comparisons/${id}`,
    method: "DELETE",
    data,
    options: {
      headers: { "Content-Type": "multipart/form-data" },
    },
  });
}

export function setDefaultOptions(data: any) {
  return {
    type: SET_DEFAULT_COST_OPTIONS,
    payload: data,
  };
}

export function RateComparisonReducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case RESET_STORE:
      return initialState;
    case SET_DEFAULT_COST_OPTIONS:
      return {
        ...state,
        defaultOptions: action.payload,
      };
    default:
      return state;
  }
}
